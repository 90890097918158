.service-card1 {
  background-color: #f8f9fa;
  padding: 40px;
  margin: 30px auto;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  text-align: left;
  transition: transform 0.3s ease;
  overflow: hidden;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.service-icon {
  font-size: 60px;
  color: #6a1b9a;
  margin-right: 15px;
}

h3 {
  font-size: 32px;
  color: #333;
  margin: 0;
}

.service-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 25px;
}

.service-content {
  padding: 0 15px;
}

p {
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  margin-bottom: 20px;
}

.service-list {
  margin: 10px 0;
  padding-left: 20px;
}

.service-list li {
  font-size: 18px;
  margin-bottom: 10px;
  color: #444;
  list-style: disc;
}

/* Responsive */
@media (max-width: 768px) {
  .service-card {
    padding: 30px;
    margin: 20px;
  }

  .service-icon {
    font-size: 45px;
  }

  h3 {
    font-size: 26px;
  }

  p, .service-list li {
    font-size: 16px;
  }
}
