/* Services.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}

.service-container2 {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.service-sidebar2 {
  flex: 1 1 250px;
  background-color: #343a40;
  padding: 20px;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;

}

.service-sidebar h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 2px solid #ffc107;
  padding-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.service-sidebar2 ul {
  list-style: none;
  padding: 0;
  gap: 10px;

}
.service-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #ffc107;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.service-item .icon {
  margin-right: 10px;
}

.service-item:hover {
  background-color: #495057;
  border-radius: 8px;
}

.service-content {
  flex: 3 1 600px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.service-content h1 {
  font-size: 36px;
  color: #343a40;
  margin-bottom: 10px;
}

.breadcrumb {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 20px;
}

.service-overview h2 {
  font-size: 28px;
  color: #007bff;
  margin-bottom: 15px;
}

.service-overview h3 {
  font-size: 22px;
  color: #495057;
  margin-top: 30px;
  margin-bottom: 10px;
}

.service-overview p {
  font-size: 16px;
  color: #6c757d;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .service-container2 {
    flex-direction: column;
  }

  .service-sidebar2 ul {
    margin-bottom: 20px;
    width: 80%;
    padding: 10px;
  }

  .service-content {
    padding: 20px;
  }

  .service-content h1 {
    font-size: 30px;
  }

  .service-overview h2 {
    font-size: 24px;
  }

  .service-overview h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .service-content {
    padding: 15px;
  }

  .service-content h1 {
    font-size: 26px;
  }

  .service-overview h2 {
    font-size: 20px;
  }

  .service-overview h3 {
    font-size: 18px;
  }

  .service-overview p {
    font-size: 14px;
  }
}
