/* Navbar.css */
.navbar {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
    backdrop-filter: blur(10px);
    padding: 15px 30px;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-container {
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo img {
    max-width: 60px;
    background-size: cover;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    gap: 20px;
    align-items: center;
  }
  
  .nav-links a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .nav-links a:hover {
    color: #7b2ff7;
  }
  
  .services:hover .dropdown {
    display: block;
  }
  
  .dropdown {
    display: none;
    position: absolute;
    top: 65%;
    left: 37%;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .dropdown li {
    padding: 10px 20px;
    white-space: nowrap;
  
  }
  
  .dropdown li a {
    color: #333;
  }
  
  .dropdown li a:hover {
    color: #7b2ff7;
  }
  
  .explore-button .Link {
    background: linear-gradient(90deg, #ff007f, #663399);
    border: none;
    padding: 10px 20px;
    color: white;
    font-size: 1rem;
    border-radius: 50px;
    cursor: pointer;
    transition: background 0.3s ease;
    text-decoration: none;
  }
  
  .explore-button .Link:hover {
    background: linear-gradient(90deg, #663399, #ff007f);
  }
  
  /* Hamburger Menu */
  .hamburger {
    display: none;
    cursor: pointer;
  }
  
  .hamburger .icon {
    font-size: 2rem;
    color: #333;
  }
  
  /* Mobile Navigation */
  .nav-links-mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.95);
    text-align: center;
    padding-top: 100px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .nav-links-mobile li {
    margin: 20px 0;
  }
  
  .nav-links-mobile a {
    font-size: 1.5rem;
    color: #333;
    text-decoration: none;
    font-weight: bold;
  }
  
  .nav-links-mobile a:hover {
    color: #7b2ff7;
  }
  
  .explore-button-mobile {
    margin-top: 40px;
  }
  
  .explore-button-mobile button {
    background: linear-gradient(90deg, #ff007f, #663399);
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 50px;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .nav-links {
      display: none;
    }
  
    .hamburger {
      display: block;
    }
    .explore-button Link{
      display: none;
    }
  }
  