/* Footer */
.footer {
    background-color: #1f2937; 
    color: #fff;
    padding: 40px 20px;
  }
  
  .footer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
  }
  
  .footer-section h3 {
    font-size: 1.5rem;
    color: #10b981; 
    margin-bottom: 15px;
  }
  
  .footer-section p,
  .footer-section li {
    font-size: 1rem;
    color: #9ca3af;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section li {
    margin-bottom: 10px;
  }
  
  .footer-section a {
    color: #9ca3af; 
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section a:hover {
    color: #10b981; 
  }
  
  .social-icons a:hover {
    color: #1da1f2;
  }
  .social-icon{
  font-size: 1.6rem;
  gap: 15px;
  margin-top: 10px;
  display: flex;
  
  }
  
  .social-icons a:nth-child(1):hover {
    color: #0077b5; 
  }
  
  .social-icons a:nth-child(2):hover {
    color: #333;
  }
  
  /* Responsive */
  @media (max-width: 768px) {

  
    .team-grid {
      grid-template-columns: 1fr;
    }
  
    .footer-grid {
      grid-template-columns: 1fr;
    }
  
  }
  