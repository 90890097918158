.new-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6; /* Optional light background */
}

.new-loader {
  position: relative;
  width: 64px;
  height: 64px;
}

.new-loader div {
  position: absolute;
  border: 4px solid #007bff; /* Blue color for the border */
  border-radius: 50%;
  animation: new-loader-animation 1.2s infinite ease-in-out;
}

.new-loader div:nth-child(1) {
  width: 64px;
  height: 64px;
  top: 0;
  left: 0;
  animation-delay: 0s;
}

.new-loader div:nth-child(2) {
  width: 48px;
  height: 48px;
  top: 8px;
  left: 8px;
  animation-delay: -0.3s;
}

.new-loader div:nth-child(3) {
  width: 32px;
  height: 32px;
  top: 16px;
  left: 16px;
  animation-delay: -0.6s;
}

@keyframes new-loader-animation {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
}
