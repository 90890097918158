/* src/pages/AboutPage.css */
.about-page {
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .about-container {
    max-width: 1200px;
    margin: 0 auto;
    overflow-x: hidden;
  }
  
  .about-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .about-header h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .about-header p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .about-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .about-section {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-section h2 {
    font-size: 2rem;
    color: #6a1b9a;
    margin-bottom: 1rem;
  }
  
  .about-section p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  .contact-footer {
    text-align: center;
    margin-top: 2rem;
  }
  
  .contact-footer p {
    font-size: 1rem;
    color: #666;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
    }
  
    .team-member {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .team-img {
      width: 80px;
      height: 80px;
    }
  }
  