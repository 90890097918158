body {
  font-family: Arial, sans-serif;
}

.contact-container {
  padding: 50px 20px;
  text-align: center;
  background-color: #f4f4f4;
}

.contact-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #673ab7;
}

.contact-container p {
  font-size: 18px;
  margin-bottom: 40px;
  color: #555;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.contact-card {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
  width: 30%;
  min-width: 280px;
  transition: box-shadow 0.3s ease;
}

.contact-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.contact-icon {
  font-size: 40px;
  color: #673ab7;
  margin-bottom: 20px;
}

.contact-card h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.contact-card p {
  font-size: 18px;
  color: #777;
}

.contact-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.contact-form h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.icon-input {
  display: flex;
  align-items: center;
  position: relative;
}

.input-icon {
  position: absolute;
  left: 10px; /* Icon padding from the left */
  font-size: 20px;
  color: #777;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px 10px 10px 40px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #673ab7;
}

.form-group label {
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.submit-btn {
  background-color: #673ab7;
  color: #fff;
  padding: 12px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-btn:hover {
  background-color: #452d9d;
}

/* Responsive Styles */

@media (max-width: 1024px) {
  .contact-info {
    flex-direction: column;
  }

  .contact-card {
    width: 80%;
    margin: 0 auto 20px;
  }

  .contact-form {
    padding: 20px;
  }

  .submit-btn {
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .contact-container h1 {
    font-size: 30px;
  }

  .contact-form {
    padding: 15px;
  }

  .contact-form h2 {
    font-size: 24px;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px 8px 8px 35px;
  }

  .submit-btn {
    padding: 10px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .contact-container {
    padding: 20px 10px;
  }

  .contact-info {
    flex-direction: column;
  }

  .contact-card {
    width: 80%;
    margin-bottom: 20px;
  }

  .contact-form {
    padding: 10px;
    max-width: 100%;
  }

  .contact-form h2 {
    font-size: 20px;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
    font-size: 14px;
  }

  .submit-btn {
    padding: 8px;
    font-size: 14px;
  }

  /* Ensure icon aligns well on mobile */
  .input-icon {
    left: 8px;
    font-size: 18px;
  }

  .form-group input,
  .form-group textarea {
    padding-left: 40px; /* Adjust padding for smaller icons */
  }
}




/* popup style  */


/* Popup styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 300px;
}

.popup-icon {
  font-size: 50px;
  margin-bottom: 10px;
}

.success-icon {
  color: green;
}

.error-icon {
  color: red;
}

.popup p {
  margin-bottom: 20px;
}

.popup-close-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.popup-close-btn:hover {
  background-color: #45a049;
}
