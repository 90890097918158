/* ServiceSelection.css */

/* Main Container */
.service-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  /* Headings */
  .service-container h2 {
    font-size: 28px;
    color: #333;
    text-align: center;
    margin-bottom: 25px;
    font-weight: bold;
  }
  
  /* Service List */
  .service-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .service-item {
    background-color: #f1f1f1;
    border: 2px solid #ccc;
    color: #333;
    padding: 15px 20px;
    font-size: 18px;
    border-radius: 5px;
    width: 200px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .service-item:hover {
    background-color: #ffe5e5;
    border-color: #ff4d4d;
  }
  
  .service-item.selected {
    background-color: #ff4d4d;
    color: white;
    border-color: #ff4d4d;
  }
  
  /* Continue Button */
  .continue-btn {
    display: inline-block;
    background-color: #ff4d4d;
    color: white;
    font-size: 18px;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .continue-btn:hover {
    background-color: #ff3333;
  }
  
  .continue-btn.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Work Options for Step 2 */
  .service-step-2 .work-options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  
  .work-option {
    background-color: #f1f1f1;
    border: 2px solid #ccc;
    color: #333;
    padding: 15px 25px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .work-option:hover {
    background-color: #ffe5e5;
    border-color: #ff4d4d;
  }
  
  /* Step Navigation Buttons */
  .step-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .go-back-btn,
  .continue-btn {
    background-color: #f1f1f1;
    color: #333;
    font-size: 16px;
    padding: 12px 25px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
  }
  
  .go-back-btn:hover,
  .continue-btn:hover {
    background-color: #ffe5e5;
    border-color: #ff4d4d;

  }
  
  /* Textarea for Project Description */
  .project-description-textarea {
    width: 90%;
    height: 150px;
    padding: 15px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .project-description-textarea:focus {
    border-color: #ff4d4d;
  }
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
    .service-list,
    .work-options {
      flex-direction: column;
      gap: 15px;
    }
  
    .service-item,
    .work-option {
      width: 100%;
    }
  
    .step-navigation {
      flex-direction: column;
      gap: 10px;
    }
  
    .go-back-btn{
      width: 100%;
    }
    .continue-btn{
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .service-container {
      padding: 20px;
    }
  
    .service-container h2 {
      font-size: 24px;
    }
  }
  