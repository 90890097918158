/* General Body Styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
}

/* Portfolio Section */
.portfolio-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
}

.portfolio-header {
  text-align: center;
  font-size: 36px;
  color: #673ab7;
  margin-bottom: 40px;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.portfolio-card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
}

.card-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-content {
  padding: 20px;
}

.portfolio-title {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.portfolio-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}
