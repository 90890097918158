/* slick carousel styles */
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css"; 

/* Home Container */
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: rgb(241, 242, 245); 
  overflow-x: hidden;
}

.home-content {
  display: flex;
  align-items: center;
  max-width: 1500px;
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Main Text Section */
.home-text {
  flex: 1;
  padding-right: 20px;
}

.home-title {
  font-size: 3rem;
  color: #1f2937; 
}

.home-subtitle {
  font-size: 1.5rem;
  color: #4b5563; 
  margin: 20px 0;
}

.highlight {
  color: #10b981; 
}

.home-button {
  padding: 12px 24px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #1f2937;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.home-button:hover {
  background-color: #10b981;
}
/* Aside Image Section */
.home-aside {
  flex: 0.8;
}

.aside-image {
  max-width: 93%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s ease;
  margin-left: 1rem;
}

.aside-image:hover {
  transform: scale(1.05);
}

/* Fade-in Animation */
.fade-in-animation {
  opacity: 0;
  animation: fadeIn 2s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 5;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .home-content {
    flex-direction: column;
    text-align: center;
    height: 100vh;
  }
  .home-container{
    margin-top: 5opx;
  }
  .home-text {
margin-bottom: 20px;
  }
  .home-title {
    font-size: 2rem;
    padding: 10px;
  }

  .home-subtitle {
    font-size: 1rem;
  }

  .home-button {
    font-size: 1rem;
  }
  .aside-image{
    display: none;
  }

}

/* Team Section */
.team-section {
  padding: 60px 20px;
  background-color: #1f2937;
  color: #fff;
  text-align: center;
}

.team-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #10b981;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.team-member {
  background-color: #374151;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.member-image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.team-member h3 {
  font-size: 1.5rem;
  color: #10b981;
}

.team-member p {
  font-size: 1rem;
  color: #9ca3af; 
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-icons a {
  color: #9ca3af; 
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #10b981; 
}



/* Services Section */

.services-section {
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
}

.services-title {
  font-size: 2rem;
  color:#10b981;
  margin-bottom: 20px;
}

.services-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.service-card {
  background: white;
  border-radius: 15px;
  padding: 20px;
  max-width: 250px;
  text-align: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  font-size: 3rem;
  color: #ff007f;
}

.service-title {
  font-size: 1.5rem;
  margin: 15px 0;
  color: #333;
}

.service-description {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 20px;
}

.learn-more-btn {
  background: linear-gradient(to right, #ff007f, #663399);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  text-decoration: none;
}

.learn-more-btn:hover {
  background: linear-gradient(to right, #663399, #ff007f);
}


/* AboutUs.css */
.about-us-section {
  padding: 60px 20px;
  background-color: #1f2937;
}

.about-us-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 50px;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.about-us-image img {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
}

.about-us-content {
  max-width: 600px;
  overflow: hidden;
}

.about-us-subtitle {
  font-size: 1.6rem;
  font-weight: bold;
  color: #10b981; 
  margin-bottom: 10px;
  position: relative;
}

.about-us-subtitle::before {
  content: "";
  width: 40px;
  height: 4px;
  background: #8d99ae;
  display: block;
  margin-bottom: 10px;
}

.about-us-title {
  font-size: 2.5rem;
  color: #edf2f4; /* White color */
  margin-bottom: 20px;
  font-weight: 700;
}

.about-us-title span {
  color: #2ed573; /* Light green for emphasis */
}

.about-us-description {
  font-size: 1.1rem;
  color: #edf2f4; /* White for text */
  line-height: 1.8;
  margin-bottom: 30px;
}

.about-us-btn {
  background: linear-gradient(to right, #2ed573, #8d99ae);
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.about-us-btn:hover {
  background: linear-gradient(to right, #8d99ae, #2ed573);
}

/* Technologies section */

/* Technologies.css */
.technologies-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.technologies-container {
  max-width: 1200px;
  margin: 0 auto;
}

.technologies-content {
  margin-bottom: 40px;
}

.technologies-title {
  font-size: 2.5rem;
  color:#10b981;
  margin-bottom: 10px;
}

.technologies-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
}

.technologies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  overflow-x: hidden;
}

.technology-card {
  background: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  overflow-x: hidden;
}

.technology-card:hover {
  transform: translateY(-10px);
}

.technology-icon {
  font-size: 3rem;
  color: #ff007f; /* Color for the icons */
  margin-bottom: 20px;
}

.technology-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}

.technology-description {
  font-size: 1rem;
  color: #666;
}


/* Frequently asked section */
/* FaqSection.css */

.faq-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
  overflow-x: hidden;
}

.left-panel {
  flex: 1;
  min-width: 250px;
  overflow-x: hidden;
}

.right-panel {
  flex: 2;
  padding: 20px;
  overflow-x: hidden;
}

.button {
  background-color: #e0e0e0;
  color: #333;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  cursor: pointer;
}

.button.active {
  background-color: #a550ff;
  color: #fff;
}

.faq-item {
  margin-bottom: 20px;
}

.question {
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.answer {
  margin-top: 10px;
  font-size: 16px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .faq-container {
    flex-direction: column;
  }

  .left-panel {
    margin-bottom: 20px;
  }

  .right-panel {
    padding: 10px;
  }
}

/* 
CustomerSection */

/* Testimonials.css */

.testimonials-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f8fd;
  overflow-x: hidden;
}
.testimonials-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.testimonials-subtitle {
  font-size: 1.1rem;
  color: #6d6d6d;
  margin-bottom: 40px;
}

.customers-count {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: #5d1284;
  overflow-x: hidden;
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.testimonial-card {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-width: 300px;
  flex: 1;
  min-width: 250px;
  overflow-x: hidden
}

.testimonial-avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.testimonial-name {
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 10px;
}

.testimonial-country {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
}

.testimonial-text {
  font-size: 1rem;
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonials-container {
    flex-direction: column;
    align-items: center;
  }

  .testimonials-title {
    font-size: 2rem;
  }

  .customers-count {
    font-size: 1.5rem;
  }
}

/* Founder Section */
.meet-the-founder {
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: center;
  overflow-x: hidden;
}

.founder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  overflow-x: hidden;
}

.founder-image img {
  border-radius: 50px;
  max-width: 500px;
  height: 600px;
  object-fit: cover;
  aspect-ratio: 3/2;
  object-position: center;
 margin-bottom: 20px;
 overflow-x: hidden
}
.founder-info {
  max-width: 600px;
  overflow: hidden;
}

.founder-info h2 {
  font-size: 2rem;
  color: #333;
  overflow-x: hidden;
}

.founder-info h3 {
  font-size: 1.5rem;
  color: #6a1b9a;
  overflow: hidden;
}

.founder-info p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

.contact-link {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1rem;
  color: #6a1b9a;
  text-decoration: none;
  border-bottom: 2px solid #6a1b9a;
}

.contact-link:hover {
  color: #ff408c;
  border-bottom: 2px solid #ff408c;
}

.social-media {
  margin-top: 1rem;
}

.social-media a {
  margin: 0 0.5rem;
  color: #333;
  font-size: 2rem;
  transition: color 0.3s;
}

.social-media a:hover {
  color: #40ffb6; /* Highlight color for icons */
}
/* Responsive Design */
@media (max-width: 768px) {
  .founder-image img{
    max-width: 300px;
  }
}